import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { breakpoints, getStoredMarket, useShoppingCart, useAuth, getStoredDistributionCenter } from '@ecommerce/shared'
import Layout from '../components/Layout'
import withPageTransition from '../components/withPageTransition'
import { useCatalogHitClick } from '../hooks/useCatalogHitClick'
import { PgPageProps } from '../types/PgPages'
import { ClientSideBlock } from '../graphql/contentfulTypes'
import useQuery from '../hooks/useQuery'
import { sendPageViewEvent } from '../utils/events'
import ReturnabilityContainer from '../components/ReturnabilityContainer'
import { BannerFluidWidget } from '../components/BannerFluid'

const blocksQuery = `query ReturnabilityPage {
  pgPageCollection(where: { pageType: "returnability" }) {
    items {
      title
      description
      keywords
      template {
        ... on MlBannerFluid {
          sys {
            id
          }
        }
      }
      blocksCollection {
        items {
          blockType
          sys {
            id
          }
        }
      }
    }
  }
  brandCollection(limit: 200)  {
    items {
      image {
        url
      }
      name
      id
      brandScore
    }
  }
}

`

export type BrandContentful = {
  image: {
    url: string
  }
  name: string
  id: string
  brandScore?: number
  contentfulid: string
}
type BlocksQueryResult = {
  pgPageCollection: {
    items: Array<{
      title: string
      description?: string
      keywords?: string[]
      template?: {
        sys: {
          id: string
        }
      }
      blocksCollection?: {
        items?: ClientSideBlock[]
      }
    }>
  }
  brandCollection: {
    items: Array<BrandContentful>
  }
}

const WrapperContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 234px);
  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 206px);
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    min-height: calc(100vh - 257px);
  }
`

const Returnability = (props: PgPageProps<{ promotionsId: string }>) => {
  const { location, pageContext, data } = props
  const { productCategories, currentMarket, currentDistributionCenter } = pageContext

  const storedMarket = getStoredMarket() || currentMarket
  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, event: 'returnabilityPageView', title: 'Retornables' })
  }, [])

  const onCatalogHitClick = useCatalogHitClick({ listName: 'Retornables' })

  const refinementList = location?.state?.refinementList
  const refinementListSelected = refinementList && Object.keys(refinementList).filter((item) => refinementList[item])

  const { data: queryData, loading } = useQuery<BlocksQueryResult>(blocksQuery, {
    variables: { slug: storedMarket?.slug },
  })

  const clientSideBlocks = queryData?.pgPageCollection?.items?.[0]?.blocksCollection?.items?.map((block) => ({
    blockType: block.blockType,
    contentful_id: block.sys.id,
  }))
  const blocks = !queryData || loading ? data?.allContentfulPgPage?.edges?.[0]?.node?.blocks : clientSideBlocks
  const brands = queryData?.brandCollection?.items ?? []

  const description = queryData?.pgPageCollection?.items?.[0]?.description
  const keywords = queryData?.pgPageCollection?.items?.[0]?.keywords

  const bannerFluidId = queryData?.pgPageCollection?.items?.[0]?.template?.sys.id

  return (
    <Layout
      id="layout"
      title="Retornables"
      pathName="/returnability"
      categories={productCategories}
      description={description}
      keywords={keywords}
    >
      <WrapperContainer>
        {bannerFluidId ? <BannerFluidWidget contentfulId={bannerFluidId} /> : null}
        <ReturnabilityContainer
          distributionCenter={storedDistributionCenter}
          refinementListSelected={refinementListSelected}
          onHitClick={onCatalogHitClick}
          blocks={blocks ?? []}
          categories={productCategories}
          brands={brands}
        />
      </WrapperContainer>
    </Layout>
  )
}

export default withPageTransition(Returnability)

export const query = graphql`
  query ReturnabilityPageQuery {
    allContentfulPgPage(filter: { pageType: { eq: "returnability" } }) {
      edges {
        node {
          title
          description
          keywords
          blocks {
            contentful_id
            blockType
          }
        }
      }
    }
    allContentfulBrand {
      brands: edges {
        node {
          image {
            file {
              url
            }
          }
          name
          id
          brandScore
          contentfulid
        }
      }
    }
  }
`
