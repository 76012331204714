import { breakpoints, toCssPrefix, ZIndex } from '@ecommerce/shared'
import styled from 'styled-components'

const { cssPrefix, toPrefix } = toCssPrefix('PromotionContainer__')

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px 0 20px;
`

export const Wrapper = styled.div<{ isOpenFilter: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;

  .${cssPrefix} {
    &left {
      width: ${(props) => (props.isOpenFilter ? '100%' : '0')};
      height: 100vh;
      top: 0;
      right: 0;
      position: fixed;
      transition: 100ms;
      z-index: ${ZIndex.ultraHigh};

      &-title,
      &-go_back {
        display: none;
      }
    }
    &right {
      width: 100%;
      overflow: hidden;
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &left {
        width: ${(props) => (props.isOpenFilter ? '375px' : '0')};
      }
    }
  }

  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &left {
        width: 22%;
        height: auto;
        right: auto;
        position: relative;
        z-index: inherit;
        &-go_back {
          width: auto;
          height: 30px;
          display: flex;
          align-items: center;
          svg {
            width: 17px;
            height: 17px;
          }
          span {
            width: auto;
            padding-left: 3px;
            font-size: 14px;
            color: ${(props) => props.theme.colors.black};
            cursor: pointer;
          }
        }
      }

      &right {
        width: 74%;
      }
    }
  }
`
Wrapper.defaultProps = {
  className: cssPrefix,
}

export const Title = styled.div`
  width: 100%;
  height: 56px;
  padding: 0 20px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${ZIndex.low};
  .${cssPrefix} {
    &right {
      &-current {
        width: 155px;
        font-size: 15px;
        color: ${(props) => props.theme.colors.black};
      }
      &-stats {
        width: 104px;
        font-size: 15px;
        color: ${(props) => props.theme.colors.black};
      }
      &-filter {
        width: 67px;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        svg {
          width: 18px;
          height: 18px;
          object-fit: contain;
          color: ${(props) => props.theme.colors.black};
        }
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &right {
        &-current {
          font-size: 18px;
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    height: 40px;
    padding: 0 0 0 20px;
    justify-content: space-between;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey};
    .${cssPrefix} {
      &right {
        &-current {
          width: 236px;
          font-size: 24px;
          font-weight: bold;
          color: ${(props) => props.theme.colors.black};
        }
        &-filter {
          display: none;
        }
      }
    }
  }
`
export const BlockedBox = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(3, 15, 28, 0.79);
  position: fixed;
  z-index: ${ZIndex.ultraHigh};
`

export { toPrefix }
