import React, { useEffect, useMemo, useState } from 'react'
import {
  Anchor,
  RETURNABLE_PLUS_BOTTLE_TAG_BO,
  RETURNABLE_PLUS_BOTTLE_TAG_CL,
  RETURNABLE_TAG_BO,
  RETURNABLE_TAG_CL,
  slugify,
  useLocation,
  useResolution,
} from '@ecommerce/shared'
import { Configure } from 'react-instantsearch-dom'
import { navigate } from 'gatsby'
import { RemoveScroll } from 'react-remove-scroll'
import { Icon } from '../Icon/Icon'
import Filter from '../Algolia/Filter'
import Loading from '../ShowLoading'
import IsFilter from '../Algolia/FilterSelected'
import ResultProducts from '../Algolia/ResultProducts'
import { InfoEmptySearch, sortListAlgolia } from '../../mocks'
import CustomSort from '../Algolia/CustomSort'
import { Container, Wrapper, Title, BlockedBox, toPrefix } from '../PromotionContainer/styled'
import { ContainerProps } from '../PromotionContainer/types'
import PromotionPageBlocks from '../PromotionContainer/Blocks'
import ReturnabilityFilter from './ReturnabilityFilter'
import FiltersrReturnabilitySearch from '../../mocks/FiltersrReturnabilitySearch'
import { BrandContentful } from '../../pages/returnability'
import Brands from '../CategoryContainer/Brands'
import { useAppDispatch, useAppSelector } from '../../store'
import { selectReturnabilityConfigure, setReturnabilityFilters } from '../../store/algolia/returnabilityStateSlice'
import {
  resetFilters,
  resetRefinementLists,
  selectIndexName,
  selectRefinementList,
  setRefinementList,
} from '../../store/algolia/searchStateSlice'
import useRefinementListQuery from '../../hooks/useRefinementListQuery'
import useSelectedRefinementList from '../../hooks/useSelectedRefinementList'

type Props = ContainerProps & {
  brands: BrandContentful[]
}

const ReturnabilityContainer = (props: Props) => {
  const { textByCountry } = useLocation()

  const categoriesFilters = useMemo(
    () => [
      {
        label: textByCountry('Producto + Envase', 'Producto + Botella'),
        itemType: [textByCountry(RETURNABLE_PLUS_BOTTLE_TAG_CL, RETURNABLE_PLUS_BOTTLE_TAG_BO)],
        attribute: 'returnabilityLabel',
        index: 0,
      },
      {
        label: textByCountry('Retornar mi envase', 'Retornar mi botella'),
        itemType: [textByCountry(RETURNABLE_TAG_CL, RETURNABLE_TAG_BO)],
        attribute: 'returnabilityLabel',
        index: 0,
      },
      {
        label: textByCountry('Bebidas', 'Gaseosas'),
        mainCategoryName: [textByCountry('BEBIDAS', 'GASEOSA')],
        attribute: 'mainCategoryName',
        index: 0,
      },
      { label: 'Aguas', mainCategoryName: ['AGUAS'], attribute: 'mainCategoryName', index: 0 },
    ],
    [textByCountry],
  )

  const dispatch = useAppDispatch()

  const returnabilityConfigure = useAppSelector(selectReturnabilityConfigure)
  const indexName = useAppSelector(selectIndexName)

  const selectedRefinementList = useAppSelector(selectRefinementList)

  const { refinementListSelected, onHitClick, blocks = [], brands, distributionCenter } = props

  const slugLocation = slugify(distributionCenter?.commerceLayer.stockLocation.name || '')

  const [isOpenFilter, setIsOpenFilter] = useState(false)

  const { isDesktop } = useResolution()

  const filters = [...FiltersrReturnabilitySearch.map((filter) => filter.filter), 'brandName', 'itemType']

  const onToggleFilter = () => setIsOpenFilter((prev) => !prev)

  useRefinementListQuery(filters, setRefinementList, null)
  useSelectedRefinementList(selectedRefinementList, null, undefined)

  useEffect(() => {
    if (distributionCenter) {
      dispatch(
        setReturnabilityFilters({
          categoryNames: [],
        }),
      )
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetFilters())
      dispatch(resetRefinementLists())
    }
  }, [dispatch])

  return (
    <Container>
      <Loading />
      {isOpenFilter && <BlockedBox />}
      {blocks && blocks.length ? <PromotionPageBlocks blocks={blocks} /> : null}
      <Wrapper isOpenFilter={isOpenFilter}>
        <div className={toPrefix('left')}>
          <Anchor className={toPrefix('left-go_back')} onClick={() => navigate(`/`)}>
            <Icon iconId="arrow_left" />
            <span>Volver a inicio</span>
          </Anchor>
          <RemoveScroll enabled={isOpenFilter}>
            <Filter
              onCloseFilter={onToggleFilter}
              filters={FiltersrReturnabilitySearch}
              refinementListSelected={refinementListSelected}
            />
          </RemoveScroll>
        </div>

        <div className={toPrefix('right')}>
          <Title className={toPrefix('right-title')}>
            <span className={toPrefix('right-current')}>Retornables</span>
            {isDesktop && <CustomSort items={sortListAlgolia} defaultRefinement={indexName} />}
            <Anchor className={toPrefix('right-filter')} onClick={onToggleFilter}>
              <IsFilter />
              <span>Filtros</span>
            </Anchor>
          </Title>
          <Configure
            filters={`slugLocation:${slugLocation} AND ${returnabilityConfigure.filters}`}
            hitsPerPage={returnabilityConfigure.hitsPerPage}
          />
          <Brands
            brands={brands.map((brand) => ({
              label: brand.name || '',
              pimId: brand.id || undefined,
              src: brand.image.url || '',
              score: brand.brandScore ?? 0,
            }))}
            hideFilter
          />

          <ReturnabilityFilter
            categoriesFilters={categoriesFilters}
            filterLabel="¿Qué tipo de retornable buscas?"
            urlImage="https://images.ctfassets.net/16npdkkoi5mj/6M8QNmddUrGSuBf9YdZnJW/e76b1ce6d88550326483d1b77312b377/botella.png"
          />
          <ResultProducts
            urlImage={InfoEmptySearch.urlImage}
            text={InfoEmptySearch.text}
            onHitClick={onHitClick}
            listName="Returnability"
          />
        </div>
      </Wrapper>
    </Container>
  )
}

export default ReturnabilityContainer
